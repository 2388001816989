<template>
  <div class="countdown-timer text-center py-10">
    <h2 class="text-3xl font-bold mb-6">Launching In</h2>
    <div class="flex justify-center space-x-6 text-4xl font-semibold">
      <div v-for="(value, label) in timeLeft" :key="label">
        <span>{{ value }}</span>
        <span class="block text-lg font-normal">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      targetDate: new Date('2024-12-25T00:00:00').getTime(),
      timeLeft: {
        Days: '00',
        Hours: '00',
        Minutes: '00',
        Seconds: '00',
      },
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const distance = this.targetDate - now;

        if (distance < 0) {
          clearInterval(this.startCountdown);
          this.timeLeft = {
            Days: '00',
            Hours: '00',
            Minutes: '00',
            Seconds: '00',
          };
        } else {
          this.timeLeft.Days = Math.floor(distance / (1000 * 60 * 60 * 24));
          this.timeLeft.Hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.timeLeft.Minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          this.timeLeft.Seconds = Math.floor(
            (distance % (1000 * 60)) / 1000
          );
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.countdown-timer div span {
  display: block;
}
</style>
