<template>
  <section class="features py-20 bg-white text-center">
    <h2 class="text-3xl font-bold mb-10">What We Offer</h2>
    <ul class="space-y-8">
      <li v-for="(feature, index) in features" :key="index" class="max-w-xl mx-auto">
        <h3 class="text-2xl font-semibold mb-2">{{ feature.title }}</h3>
        <p class="text-lg">{{ feature.description }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection',
  data() {
    return {
      features: [
        {
          title: 'Simplicity at Its Best',
          description: 'We believe in making things simple, so you can focus on what matters.',
        },
        {
          title: 'Flexibility for All Needs',
          description: 'Whether you’re a beginner or an expert, our platform adapts to your needs.',
        },
        {
          title: 'Seamless Experience',
          description: 'Enjoy a smooth and intuitive experience that lets you achieve more with less effort.',
        },
      ],
    };
  },
};
</script>
