<template>
  <div>
    <!-- Hero Section -->
    <section class="hero h-screen relative flex flex-col items-center justify-center text-center">
      <!-- Background Video -->
      <video
        alt="WebTree Tech"
        v-for="(video, index) in videos"
        :key="index"
        :src="video"
        autoplay
        muted
        loop
        class="hero-video absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out"
        :class="{ 'opacity-0': currentVideoIndex !== index, 'opacity-100': currentVideoIndex === index }"
      ></video>

      <!-- Content Overlay -->
      <div class="relative z-10 text-gray-300 text-center">
        <img src="/webtree_greenwhite.png" class=" w-96 mb-10 inline-block">
        <h1 class="text-4xl font-bold mb-4">Empowering Your Online Presence</h1>
        <p class="text-xl mb-8">Join the revolution in effortless web design and management, tailored for everyone.</p>
        <CountdownTimer />
          <button @click="scrollToContactForm" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-8">
            Get Notified When We Launch
          </button>
      </div>
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-70 z-5"></div>
    </section>

    <!-- Features Section -->
    <section class="features py-20 bg-white text-center">
      <h2 class="text-3xl font-bold mb-10 text-gray-600">Key Features</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-6xl mx-auto text-gray-300">
        <div class="feature-item p-6 rounded-lg shadow-md" style="background-color: #17252A;">
          <h3 class="text-2xl font-semibold mb-2">Website Builder</h3>
          <p class="text-lg">Create stunning websites with ease using our intuitive builder.</p>
        </div>
        <div class="feature-item p-6 rounded-lg shadow-md" style="background-color: #17252A;">
          <h3 class="text-2xl font-semibold mb-2">Content Management</h3>
          <p class="text-lg">Manage your website's content seamlessly without any technical knowledge.</p>
        </div>
        <div class="feature-item p-6 rounded-lg shadow-md" style="background-color: #17252A;">
          <h3 class="text-2xl font-semibold mb-2">SEO Optimization</h3>
          <p class="text-lg">Boost your online presence with built-in SEO tools.</p>
        </div>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section class="contact bg-white text-center block " ref="contactForm" >
      <iframe
        style="border:none;width:100%; height: 600px;"
        id="get-in-touch-ksr951"
        src="https://noteforms.com/forms/get-in-touch-ksr951"
      ></iframe>
    </section>

    <!-- Footer Section -->
    <footer class="py-10 bg-gray-800 text-white text-center">
      <p class="text-lg">&copy; 2024 WebTree Tech. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
// import CountdownTimer from './CountdownTimer.vue';

export default {
  name: 'LandingPage',
  // components: {
  //   CountdownTimer,
  // },
  data() {
    return {
      currentVideoIndex: 0,
      videos: [
        '/video1.mp4',
        '/video2.mp4',
        '/video3.mp4',
      ],
      form: {
        name: '',
        email: '',
        message: '',
      },
      posts: [
        {
          title: 'The Future of Web Design: Trends to Watch',
          excerpt: 'Explore the latest trends shaping the future of web design and development.',
          url: '#',
        },
        {
          title: 'How to Build an Effective Online Presence',
          excerpt: 'Tips and strategies for establishing a strong digital footprint.',
          url: '#',
        },
      ],
    };
  },
  mounted() {
    // Load the external script dynamically
    this.startVideoTransition();
    const script = document.createElement('script');
    script.src = 'https://noteforms.com/widgets/iframe.min.js';
    script.onload = () => {
      // Initialize the embed (if necessary)
      if (window.initEmbed) {
        window.initEmbed('get-in-touch-ksr951');
      }
    };
    document.body.appendChild(script);
  },
  methods: {
    scrollToContactForm() {
      const contactForm = this.$refs.contactForm;
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: 'smooth' });
      }
    },
    submitForm() {
      alert(`Thank you, ${this.form.name}! Your message has been sent.`);
      // Reset form
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    },
    startVideoTransition() {
      setInterval(() => {
        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videos.length;
      }, 4000); // Change video every 10 seconds
    },
  },
};
</script>
