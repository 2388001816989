<template>
  <section class="contact-form py-20 bg-white text-center">
    <h2 class="text-3xl font-bold mb-10">Stay in Touch</h2>
    <form @submit.prevent="submitForm" class="max-w-lg mx-auto space-y-6">
      <input 
        type="text" 
        v-model="form.name" 
        placeholder="Your Name" 
        required
        class="w-full p-4 border border-gray-300 rounded-lg"
      />
      <input 
        type="email" 
        v-model="form.email" 
        placeholder="Your Email" 
        required
        class="w-full p-4 border border-gray-300 rounded-lg"
      />
      <textarea 
        v-model="form.message" 
        placeholder="Your Message" 
        required
        class="w-full p-4 border border-gray-300 rounded-lg h-32"
      ></textarea>
      <button 
        type="submit"
        class="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600"
      >
        Send Message
      </button>
    </form>
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  methods: {
    submitForm() {
      alert('Message sent!');
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    },
  },
};
</script>
