<template>
  <section class="hero text-center py-20 bg-gray-100">
    <h1 class="text-4xl font-bold mb-4">Empowering Your Online Presence</h1>
    <p class="text-xl mb-8">Join the revolution in effortless web design and management, tailored for everyone.</p>
    <CountdownTimer />
    <router-link to="/contact">
      <button class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        Get Notified About Our Launch
      </button>
    </router-link>
  </section>
</template>

<script>
import CountdownTimer from './CountdownTimer.vue';

export default {
  name: 'HomeSection',
  components: {
    CountdownTimer,
  },
};
</script>
