<template>
  <section class="blog py-20 bg-gray-50 text-center">
    <h2 class="text-3xl font-bold mb-10">Insights & Inspiration</h2>
    <ul class="space-y-8">
      <li v-for="(post, index) in posts" :key="index" class="max-w-xl mx-auto">
        <h3 class="text-2xl font-semibold mb-2">{{ post.title }}</h3>
        <p class="text-lg mb-4">{{ post.excerpt }}</p>
        <a :href="post.url" class="text-blue-500 hover:underline">Read More</a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'BlogSection',
  data() {
    return {
      posts: [
        {
          title: 'The Future of Web Design: Trends to Watch',
          excerpt: 'Explore the latest trends shaping the future of web design and development.',
          url: '#',
        },
        {
          title: 'How to Build an Effective Online Presence',
          excerpt: 'Tips and strategies for establishing a strong digital footprint.',
          url: '#',
        },
      ],
    };
  },
};
</script>
