import { createRouter, createWebHistory } from 'vue-router';
import HomeSection from './components/HomeSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import BlogSection from './components/BlogSection.vue'; // Updated
import ContactSection from './components/ContactSection.vue';

const routes = [
  { path: '/', name: 'HomeSection', component: HomeSection },
  { path: '/features', name: 'FeaturesSection', component: FeaturesSection },
  { path: '/blog', name: 'BlogSection', component: BlogSection }, // Updated
  { path: '/contact', name: 'ContactSection', component: ContactSection },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
